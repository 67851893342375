import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { OverlayProvider } from '@react-aria/overlays';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import styled from 'styled-components';
import RouterProgressBar from './router-progress-bar';
import Modal, { useModal } from './modal';
import { POPUP_MODAL_TIMEOUT_DEFAULT } from './enums';
import { useIsWeekend } from '../lib/hooks';
import { WpHeader } from './header/header';

const Header = dynamic(() => import('./header/header'));
const Footer = dynamic(() => import('./footer/footer'));

const EmptyHeader = dynamic(() =>
  import('./header/header').then((mod) => mod.EmptyHeader)
);
const EmptyFooter = dynamic(() =>
  import('./footer/footer').then((mod) => mod.EmptyFooter)
);
const Banner = dynamic(() =>
  import('./banner/banner').then((mod) => mod.Banner)
);

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  @media screen and (min-width: 1128px) {
    padding: var(--mainPaddingDesktop) 0;
  }
`;

export const triggerModalHasToBeClosed = () => {
  const modalHasToBeClosedEvent = new Event('modalHasToBeClosed');
  document.dispatchEvent(modalHasToBeClosedEvent);
  document.getElementsByTagName('html')[0].setAttribute('style', '');
};

export const Layout = ({ children, pageProps }) => {
  const router = useRouter();
  const { pathname } = router;
  const lang = pathname.startsWith('/ca') ? 'ca' : 'es';
  const isSimpleLayout = pageProps?.pageContext?.simpleLayout ? true : false;
  const popupModal = pageProps?.pageContext?.popupModal;
  const { modalProps, state } = useModal();
  const bannerArray = Object.values(pageProps?.banners);
  const isWeekend = useIsWeekend();

  useEffect(() => {
    if (popupModal) {
      const { popupModalTimeout } = pageProps.pageContext;
      setTimeout(() => {
        state.open();
      }, popupModalTimeout || POPUP_MODAL_TIMEOUT_DEFAULT);
    }
  }, [popupModal]);

  useEffect(() => {
    document.documentElement.lang = lang;
  }, [lang]);

  const bannerToRender = isWeekend ?
    bannerArray.find(banner => banner.show && banner.showOnWeekendsOnly) :
    bannerArray.find(banner => banner.show && !banner.showOnWeekendsOnly);

  return (
    <OverlayProvider>
      <Script
        src="https://js.hsforms.net/forms/v2.js"
        strategy="beforeInteractive"
        id="hubspotFormScript"
      />
      <Script
        src="/js/jquery-3.6.0.slim-min.js"
        strategy="afterInteractive"
        id="jquery"
      />
      <RouterProgressBar />
      {isSimpleLayout ? (
        <EmptyHeader {...pageProps} />
      ) :
        (<Header {...pageProps} />
        )}
      <>
        {bannerToRender && (
          <Banner {...bannerToRender} />
        )}
      </>
      <Main>{children}</Main>
      {isSimpleLayout ? (
        <EmptyFooter {...pageProps.footer} />
      ) : (
        <Footer {...pageProps.footer} />
      )}

      {(!isSimpleLayout || popupModal) && (
        <Modal {...modalProps} {...popupModal} />
      )}
    </OverlayProvider>
  );
};

export default Layout;