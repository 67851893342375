import styled, { css } from "styled-components";

const ButtonLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacingS);
  height: var(--spacing2Xl);
  max-width: 100%;
  color: var(--gray);
  text-decoration: none;
  text-align: center;
  background-color: var(--gray100);
  border-radius: var(--radiusXs);
  padding: 0 var(--spacingM);
  font-size: var(--textM);
  line-height: calc(var(--spacing2Xl) - 4px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 0;
  cursor: pointer;
  transition: all 0.2s;

  &:hover,
  &:focus {
    background-color: var(--gray200);
  }

  ${({ bigText }) =>
    bigText &&
    css`
      font-size: var(--textL);
    `}

  @media screen and (max-width: 400px) {
    padding: 0 var(--spacingS);
    gap: var(--spacingXs);
  }

  > img {
    max-width: var(--spacingL);
    max-height: var(--spacingL);
  }
`;

export const ButtonGhost = styled(ButtonLink)`
  border: 1px solid var(--gray200);
  background-color: transparent;
  &:hover {
    background-color: var(--gray100);
  }
`;

export const ButtonHeaderLink = styled(ButtonLink)`
  &:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`;

export const ButtonPrimaryLink = styled(ButtonLink)`
  color: white;
  background-color: var(--primary800);

  &:hover,
  &:focus {
    background-color: var(--primary400);
  }
`;

export const ButtonCtaLink = styled(ButtonLink)`
  background-color: transparent;
  color: var(--cta800);
  border: 1px solid var(--cta800);
  &:hover,
  &:focus {
    color: white;
    background-color: var(--cta400);
  }
`;

export const ButtonMenuLink = styled(ButtonLink)`
  border-radius: 0;
  padding: var(--spacingS) var(--spacingM);
  line-height: calc(var(--spacingS) - 4px);
`;

export const ButtonCustomHex = styled(ButtonLink)`
color: white;
background-color: ${({ hexCode }) =>
    hexCode ? hexCode : "var(--primary800)"};
border-radius: 20px;
&:hover,
&:focus {
  background-color: var(--primary400);
}
`;
