import { useCallback, useEffect, useRef, useState } from "react";

let lastScrollY = 0;

export function useStickyElement(defaultSticky = false) {
  const [isSticky, setIsSticky] = useState(defaultSticky);
  const [isHidden, setIsHidden] = useState(false);
  const elementRef = useRef(null);

  const toggleSticky = useCallback(
    ({ top }, scrollY) => {
      setIsHidden(scrollY > lastScrollY);
      setIsSticky(top < 0);
      lastScrollY = scrollY;
    },
    []
  );

  useEffect(() => {
    let isMounted = true;

    const handleScroll = () => {
      if (isMounted && elementRef.current) {
        toggleSticky(elementRef.current.getBoundingClientRect(), window.scrollY);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      isMounted = false;
      window.removeEventListener("scroll", handleScroll);
    };
  }, [toggleSticky]);

  return { elementRef, isSticky, isHidden };
}

export default useStickyElement;
